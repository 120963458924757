import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'


import moment from 'moment'
import 'moment/locale/de';

import { contentOptions } from './parser'

import './erlebnisnachmittag.scss'


const JanuarIntro = ({
  MaisText
}) => {
  // const [date, setDate] = useState(new Date().toISOString())
  // const [photos, setPhotos] = useState(false)

  // const handleChange = (value) => {
  //   setDate(value)
  // }

  // const dateFormat = 'dddd Do MMMM YYYY';

  // function submitForm(ev) {
  //   ev.preventDefault()
  //   const form = ev.target
  //   const data = new FormData(form)
  //   const xhr = new XMLHttpRequest()
  //   xhr.open(form.method, form.action)
  //   xhr.setRequestHeader("Accept", "application/json")
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState !== XMLHttpRequest.DONE) return
  //     if (xhr.status === 200) {
  //       form.reset()
  //       toast('Ihre Anfrage wurde erfolgreich gesendet',{ type: 'success' })
  //     } else {
  //       toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
  //     }
  //   }
  //   xhr.send(data)
  // }

  return (
    <Container className="wrapper">
        <Row>
          <Col>
         

            {/* <h1 className="erlebnis-titel">
              <br></br>
            Bauernhoftier-Suche
            </h1> */}
            <div className="content-block-mais">
              {MaisText?.intro?.raw
                && documentToReactComponents(JSON.parse(MaisText.intro.raw), contentOptions)} 
            </div>
            <div className="content-block-mais">
              {MaisText?.ffnungszeiten?.raw
                && documentToReactComponents(JSON.parse(MaisText.ffnungszeiten.raw), contentOptions)} 
            </div>
          
            <div className="content-block-mais">
              {MaisText?.introWettbewerb?.raw
                && documentToReactComponents(JSON.parse(MaisText.introWettbewerb.raw), contentOptions)} 
            </div>
{/*             
            <div className="content-block">
              <h3>Zielgruppe:</h3> {erlebnisnachmittag?.zielgruppe} 
            </div>
            <div className="content-block">
              <h3>Treffpunkt:</h3> {erlebnisnachmittag?.treffpunkt} 
            </div>
            <div className="content-block">
              <h3>Kurszeiten:</h3>
              <div>Start: {erlebnisnachmittag?.anfangZeit} </div>
              <div>Ende: {erlebnisnachmittag?.endZeit} </div>
            </div>
            <div className="content-block">
              <h3>Kursleitung:</h3>
              <div>
                <strong>{erlebnisnachmittag?.kursleitung?.[0]?.name}</strong>
              </div>
              <div>
                {erlebnisnachmittag?.kursleitung?.[0]?.beschreibung?.raw
                  && documentToReactComponents(JSON.parse(erlebnisnachmittag.kursleitung[0].beschreibung.raw), contentOptions)} 
              </div>
            </div>
            <div className="content-block">
              <h3>Preis:</h3> {erlebnisnachmittag?.kosten}
            </div>
            <div className="content-block">
              <h3>Anzahl Plätze:</h3> {erlebnisnachmittag?.anzahlPltze} 
            </div> */}
           
          </Col>
        </Row>
      </Container>
  )
}


export default JanuarIntro
