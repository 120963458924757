import React, { useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import DatePicker from "reactstrap-date-picker"
import { toast } from "react-toastify"
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
// import ReCAPTCHA from "react-google-recaptcha"

import moment from "moment"
import "moment/locale/de"
import "./ferienlager.scss"

import { contentOptions } from "../contentfulModels/parser"

const dateFormat = "dddd Do MMMM YYYY"

const Ferienlager = ({ ferienlager }) => {
  const [date, setDate] = useState(new Date().toISOString())
  const [photos, setPhotos] = useState(false)
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const recaptchaRef = useRef(null);

  const handleChange = value => {
    setDate(value)
  }

  function submitForm(ev) {
    console.log(ev, "ev")
    ev.preventDefault()
    const form = ev.target
    console.log(form, "form")
    const data = new FormData(form)

    // const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
    // if (!recaptchaValue) {
    //   // console.log('Execute recaptcha not yet available');
    //   return;
    // } else {
    //   data.append('g-recaptcha-response', recaptchaValue);
      console.log(data, "data")
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        toast("Ihre Anfrage wurde erfolgreich gesendet", { type: "success" })
      } else {
        toast(
          "Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal",
          { type: "error" }
        )
      }
      // };

    }
      xhr.send(data)
  }

  return (
    // <ReCAPTCHA ref={recaptchaRef} sitekey="6LeimdgkAAAAAIJQjdNnCR0kcnKIZjDx65SHn0RJ">
    <Container className="wrapper">
      <Row>
        <Col>
          <h1 className="erlebnis-titel">{ferienlager?.titel}</h1>
          <div className="content-block">
            <h3>Zielgruppe</h3> {ferienlager?.zielgruppe}
          </div>
          <div className="content-block">
            <h3>Tagesprogramm</h3>
            {ferienlager?.tagesprogramm?.raw &&
              documentToReactComponents(
                JSON.parse(ferienlager.tagesprogramm.raw),
                contentOptions
              )}
          </div>
          <div className="content-block">
            <h3>Bringen und Abholen</h3>
            {ferienlager?.bringUndAbhol?.raw &&
              documentToReactComponents(
                JSON.parse(ferienlager.bringUndAbhol.raw),
                contentOptions
              )}
          </div>
          <div className="content-block">
            <h3>Kursverantwortliche</h3>
            <div>
              <strong>{ferienlager?.kursleitung?.name}</strong>
            </div>
            <div>
              {ferienlager?.kursleitung?.beschreibung?.raw &&
                documentToReactComponents(
                  JSON.parse(ferienlager.kursleitung.beschreibung.raw),
                  contentOptions
                )}
            </div>
          </div>

          <div className="content-block">
            <h3>Preise</h3>
            {ferienlager?.preise?.raw &&
              documentToReactComponents(
                JSON.parse(ferienlager.preise.raw),
                contentOptions
              )}
          </div>

          <div className="content-block">
            <h3>Ort</h3> {ferienlager?.ort}
          </div>

          <div className="content-block">
            <h3>Daten</h3>
            <Form
              method="POST"
              onSubmit={submitForm}
              action={process.env.GATSBY_FORMSPREE_FERIENLAGER_ENDPOINT}
            >
              {ferienlager?.daten.map((item, idx) => {
                return (
                  <FormGroup required>
                    <Label for="kinder">{item?.name} </Label>

                    {item?.tag1 && (
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name={`date${idx}`}
                          value={moment(item.tag1).format(dateFormat)}
                        />{" "}
                        {moment(item.tag1).format(dateFormat)}{" "}
                        {item?.ausgebuchtTag1 && <strong>ausgebucht</strong>}
                      </FormGroup>
                    )}
                    {item?.tag2 && (
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name={`date${idx}`}
                          value={moment(item.tag2).format(dateFormat)}
                        />{" "}
                        {moment(item.tag2).format(dateFormat)}{" "}
                        {item?.ausgebuchtTag2 && <strong>ausgebucht</strong>}
                      </FormGroup>
                    )}
                    {item?.tag3 && (
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name={`date${idx}`}
                          value={moment(item.tag3).format(dateFormat)}
                        />{" "}
                        {moment(item.tag3).format(dateFormat)}{" "}
                        {item?.ausgebuchtTag3 && <strong>ausgebucht</strong>}
                      </FormGroup>
                    )}
                    {item?.tag4 && (
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name={`date${idx}`}
                          value={moment(item.tag4).format(dateFormat)}
                        />{" "}
                        {moment(item.tag4).format(dateFormat)}{" "}
                        {item?.ausgebuchtTag4 && <strong>ausgebucht</strong>}
                      </FormGroup>
                    )}
                    {item?.tag5 && (
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          name={`date${idx}`}
                          value={moment(item.tag5).format(dateFormat)}
                        />{" "}
                        {moment(item.tag5).format(dateFormat)}{" "}
                        {item?.ausgebuchtTag5 && <strong>ausgebucht</strong>}
                      </FormGroup>
                    )}
                  </FormGroup>
                )
              })}
              <FormGroup>
                <FormGroup>
                  <Label for="kinder">
                    Name des Kindes / der Kinder{" "}
                    <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="kinder"
                    id="kinder"
                    placeholder="Name des Kindes / der Kinder"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="familienname">
                    Familienname <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="familienname"
                    id="familienname"
                    placeholder="Familienname"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="kontaktperson">
                    Kontaktperson <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="kontaktperson"
                    id="kontaktperson"
                    placeholder="Kontaktperson"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="adresse">
                    Adresse <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="adresse"
                    id="adresse"
                    placeholder="Adresse"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="wohnort">
                    PLZ / Wohnort <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="wohnort"
                    id="wohnort"
                    placeholder="PLZ / Wohnort"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    Geburtsdatum (jüngstes) Kind{" "}
                    <span className="required">*</span>
                  </Label>
                  <DatePicker
                    id="example-datepicker"
                    value={date}
                    name="Geburtsdatum"
                    onChange={(v, f) => handleChange(v, f)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="wohnort">Geburtsdaten (weitere Kinder)</Label>
                  <Input
                    type="text"
                    name="Geburtsdaten"
                    id="Geburtsdaten"
                    placeholder="Geburtsdaten"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="telefonnummer">
                    Telefonnummer <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="telefonnummer"
                    id="telefonnummer"
                    placeholder="Telefonnummer"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="email">
                    E-Mail <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="fotos">Fotos </Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        value={photos}
                        checked={photos}
                        name="fotos-erlaubt"
                        onClick={() => setPhotos(!photos)}
                        type="radio"
                      />{" "}
                      Wir sind einverstanden, dass Fotos unseres Kindes auf
                      dieser Webseite veröffentlicht werden.
                    </Label>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="nachricht">Nachricht </Label>
                  <Input
                    type="textarea"
                    name="nachricht"
                    id="nachricht"
                    placeholder="Nachricht"
                  />
                </FormGroup>
              <FormGroup >
                <Label >
                  <Input style={{marginLeft:'5px'}} required type="checkbox" />{" "}
                  <span style={{ marginLeft: "25px" }}>
                    Ich bin mit den{" "}
                    <a href="https://assets.ctfassets.net/ye599n3dkmr1/2on0yEOP5T4s9Luig9dJW9/6c8651a8b7b8590433d30f71258e4aee/AGB_Fahr_2023.pdf">
                      AGB's
                    </a>{" "}
                    einverstanden
                  </span>
                </Label>
              </FormGroup>
              </FormGroup>
              {/* <div style={{ height: "20px" }}></div> */}

              <Button type="submit">Absenden</Button>
              <div style={{ height: "20px" }}></div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
    // </ReCAPTCHA>
  )
}

export default Ferienlager
