import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { contentOptions } from "../contentfulModels/parser"

import "bootstrap/dist/css/bootstrap.min.css"

import "./index.scss"
// ---------- Themes colors ----------
import "../themes/green.scss"
// import "../themes/orange.scss"
import MaisHero from "../images/Headerbild_Mais.jpeg"

import defaultHero from "../images/Header_Neu_Inhalt_Erlebnisse.jpeg"
// ---------- Components ----------
import Hero from "../components/hero"
import Tabs from "../components/tabs"
import Erlebnisnachmittag from "../contentfulModels/erlebnisnachmittag"
import Ferienlager from "../contentfulModels/ferienlager"
import SchuleErlebnisse from "../contentfulModels/schuleErlebnisse"
import Description from "../components/schuleErlebnisseCommon/description"
import ContactForm from "../components/schuleErlebnisseCommon/contactForm"
import MaisLabyrinth from "../contentfulModels/maisLabyrinth"
import MaisIntro from "../contentfulModels/maisIntro"
import Januar from "../contentfulModels/Januar"
import JanuarIntro from "../contentfulModels/JanuarIntro"
import JanuarForm from "../contentfulModels/JanuarAnmeldung"
import MaisIntroTop from "../contentfulModels/maisIntroTop"
import CookieBanner from "../components/cookieBanner"

const IndexPage = ({
  data: {
    allContentfulErlebnisprogrammIntro,
    allContentfulFerienlager,
    allContentfulSchuleErlebnisse,
    allContentfulMaisLabyrinth,
    allContentfulMaisTexte,
    allContentfulAktuellErlebnissprogrammTextDaten,
    allContentfulAktuelleErlebnisse,
    allContentfulPdfFiles,
    allContentfulDatenErlebnisProgramm,
    allContentfulAsset,
    site,
  },
}) => {
  const { fullTitle, description } = site.siteMetadata

  const erlebnisnachmittag = allContentfulErlebnisprogrammIntro.edges?.[0]?.node
  const ferienlager = allContentfulFerienlager.edges?.[0]?.node

  const MaisNodeLabyrinth = allContentfulMaisLabyrinth.edges
  const MaisText = allContentfulMaisTexte.edges?.[0]?.node

  const maisLabyrintMenuBool = MaisText.maisLabyrinthMenu
  const MaisTextBool = MaisText.textAnzeigen
  const MaisLabBool = MaisText.maisLabyrinthEvent
  const MaisTextTitle = MaisText.maisTextTitel
  const MaisTitle = MaisText.maisIntroTitel
  const MaislabyrinthIntro = MaisText.introWettbewerb.raw

  const MaisImg = allContentfulAsset.edges?.[0]?.node.file.url

  const ErlebnissprgrammText =
    allContentfulAktuellErlebnissprogrammTextDaten.edges?.[0]?.node
  const pdf = allContentfulPdfFiles.edges?.[0]?.node
  const dates = allContentfulDatenErlebnisProgramm.edges?.[0]?.node
  const Number1 = allContentfulAktuelleErlebnisse.edges?.[0]?.node
  const Number2 = allContentfulAktuelleErlebnisse.edges?.[1]?.node
  const Number3 = allContentfulAktuelleErlebnisse.edges?.[2]?.node
  // const Number4 = allContentfulAktuelleErlebnisse.edges?.[3]?.node

  
  const [MaisLab, setMaisLab] = useState([
    "Aktuell",
    "Erlebnisprogramm",
    "Maislabyrinth",
    "Ferienlager",
    "Schule",
  ])
  useEffect(() => {
    if (!maisLabyrintMenuBool) {
      setMaisLab(["Aktuell", "Erlebnisprogramm", "Ferienlager", "Schule"])
    }
  }, [MaisLabBool])

  return (
    <div>
      {maisLabyrintMenuBool ? (
        <Hero image={MaisImg} title={fullTitle} description={description} />
      ) : (
        <Hero image={defaultHero} title={fullTitle} description={description} />
      )}{" "}
      {/* <CookieBanner /> */}
      {maisLabyrintMenuBool ? (
        <Tabs
          titlesProp={MaisLab}
          content={[
            <>
              <JanuarIntro MaisText={ErlebnissprgrammText} />
               <Januar MaisErlebnisse={Number1} />
              <Januar MaisErlebnisse={Number2} />
               <Januar MaisErlebnisse={Number3} />
              {/*   <Januar MaisErlebnisse={Number4} /> */}
              {/* <JanuarForm erlebnisnachmittag={ErlebnissprgrammText} /> */}
            </>,

            <Erlebnisnachmittag
              erlebnisnachmittag={erlebnisnachmittag}
              pdf={pdf}
              daten={dates}
            />,

            <>
              {MaisTextBool ? (
                <>
                  {" "}
                  <MaisIntroTop
                    MaisText={MaisText}
                    MaisIntroTitle={MaisTitle}
                  />
                  <MaisIntro
                    MaisText={MaisText}
                    MaisIntroTitle={MaisTextTitle}
                  />{" "}
                </>
              ) : null}

              {MaisLabBool ? (
                <>
                  {MaislabyrinthIntro &&
                    documentToReactComponents(
                      JSON.parse(MaislabyrinthIntro),
                      contentOptions
                    )}
                  <div style={{ height: "12px" }}></div>

                  {MaisNodeLabyrinth.map((item, i) => (
                    <MaisLabyrinth key={i} MaisErlebnisse={item?.node} />
                  ))}
                </>
              ) : null}

              <div className="content-block-mais">
                <a href="mailto:info@fahr-erlebnis.ch">info@fahr-erlebnis.ch</a>
              </div>
            </>,

            <Ferienlager ferienlager={ferienlager} />,

            <>
              <Description />
              {allContentfulSchuleErlebnisse?.edges?.map(item => (
                <SchuleErlebnisse schuleErlebnisse={item?.node} />
              ))}
              <ContactForm
                titles={allContentfulSchuleErlebnisse?.edges.map(
                  item => item.node.titel
                )}
              />
            </>,
          ]}
        />
      ) : (
        <Tabs
          titlesProp={MaisLab}
          content={[
            <>
              <JanuarIntro MaisText={ErlebnissprgrammText} />
              <Januar MaisErlebnisse={Number1} />
              <Januar MaisErlebnisse={Number2} />
              <Januar MaisErlebnisse={Number3} />
              {/* <Januar MaisErlebnisse={Number4} /> */}
              <JanuarForm erlebnisnachmittag={ErlebnissprgrammText} />
            </>,

            <Erlebnisnachmittag
              erlebnisnachmittag={erlebnisnachmittag}
              pdf={pdf}
              daten={dates}
            />,

            <Ferienlager ferienlager={ferienlager} />,

            <>
              <Description />
              {allContentfulSchuleErlebnisse?.edges?.map(item => (
                <SchuleErlebnisse schuleErlebnisse={item?.node} />
              ))}
              <ContactForm
                titles={allContentfulSchuleErlebnisse?.edges.map(
                  item => item.node.titel
                )}
              />
            </>,
          ]}
        />
      )}
    </div>
  )
}

export const projectsQuery = graphql`
  query FeaturedProjectsQuery {
    allContentfulErlebnisprogrammIntro(
      filter: { introErlebnisprogramm: { eq: "Intro Erlebnisprogramm" } }
    ) {
      edges {
        node {
          id
          name
          spaceId
          beschreibung {
            raw
          }
        }
      }
    }

    allContentfulPdfFiles {
      edges {
        node {
          id
          file {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulAsset(filter: { title: { eq: "MaisLabyrinth" } }) {
      edges {
        node {
          id
          file {
            url
          }
        }
      }
    }

    allContentfulDatenErlebnisProgramm {
      edges {
        node {
          id
          datum1
          # datum2
          # datum3
          # datum4titel
          # datum5
          # datum6
          # datum7
          #  datum8
          #   datum9

          datum1titel
          datum1Anzeigen
          # datum2titel
          #  datum3titel
          #  datum4Tittel
          #  datum5titel
          #  datum6titel
          #  datum7titel
          #  datumTitel8
          #  datum9titel
        }
      }
    }

    allContentfulFerienlager {
      edges {
        node {
          id
          titel
          zielgruppe
          tagesprogramm {
            raw
          }
          bringUndAbhol {
            raw
          }
          kursleitung {
            name
            beschreibung {
              raw
            }
          }
          preise {
            raw
          }
          ort
          daten {
            name
            tag1
            saison
            tag2
            tag3
            tag4
            tag5
            ausgebuchtTag1
            ausgebuchtTag2
            ausgebuchtTag3
            ausgebuchtTag4
            ausgebuchtTag5
          }
        }
      }
    }

    allContentfulSchuleErlebnisse {
      edges {
        node {
          titel
          beschreibungSchule {
            raw
          }
          dauerText
          bild {
            file {
              url
            }
          }
          kursziele {
            raw
          }
        }
      }
    }

    allContentfulAktuelleErlebnisse(sort: { fields: platz }) {
      edges {
        node {
          id
          platz
          dauerText
          titel
          kosten
          bild {
            file {
              url
            }
          }
          beschreibungMais {
            raw
          }
        }
      }
    }

    allContentfulAktuellErlebnissprogrammTextDaten {
      edges {
        node {
          intro {
            raw
          }
          datum1
          datum1anzeigen
          datum2
          datum2anzeigen
          datum3
          datum3anzeigen
          datum4
          datum4anzeigen
        }
      }
    }
    allContentfulMaisLabyrinth {
      edges {
        node {
          titel
          beschreibungMais {
            raw
          }
          dauerText
          bild {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulMaisTexte {
      edges {
        node {
          maisIntroTitel
          maisTextTitel
          maisLabyrinthMenu
          textAnzeigen
          maisLabyrinthEvent
          intro {
            raw
          }
          maistext {
            raw
          }
          introWettbewerb {
            raw
          }
        }
      }
    }
    site {
      siteMetadata {
        fullTitle
        description
        about
      }
    }
  }
`

export default IndexPage
