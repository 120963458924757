import React, { useState } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';

import "./tabs.scss"

const Tabs = ({
  content,
  titlesProp
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  const titles = titlesProp
  
  return (
    <Container className="tabs-wrapper">
      <Nav className="navigation" tabs>
        {titles.map((title, idx) => {
          return (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === idx })}
                onClick={() => { toggle(idx); }}
              >
                {title}
            </NavLink>
          </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {content.map((item, idx) => {
          return (
            <TabPane tabId={idx}>
              <Row>
                <Col sm="12">
                  {item}
                </Col>
              </Row>
            </TabPane>
          )
        })}
      </TabContent>
    </Container>
  );
}

export default Tabs;