import React from "react"
import { Container, Row, Col } from "reactstrap"

import "./description.scss"

const Description = ({ about }) => {
  return (
    <Container>
      <Row className="description-block">
        <Col>
          <h1 className="erlebnis-titel-schule">Schule auf dem Bauernhof - ein tolles Erlebnis!</h1>
          <p className="intro-text">
            <strong className="strong-text">Zielgruppe</strong>
            <br />
             Kindergarten, Primarschule, Mittelstufe und Oberstufe.<br></br>
            Das Programm und die Informationstiefe wird der jeweiligen Stufe angepasst.
          </p>

          <p className="intro-text">
            <strong className="strong-text">Preise</strong>
            <br />
            <i>Klassen aus dem Kanton Zürich:</i> <br></br>Der Bauernverband Zürich übernimmt die Hälfte der Kurskosten,
            für 1/2 Tag werden wir Ihnen CHF 125.- in Rechnung stellen. Allfällige Materialkosten (z.B. beim Mosten)
            werden zusätzlich in Rechnung gestellt. 
          </p>

          <p className="intro-text">
            <i>Klassen aus dem Kanton Aargau</i> <br></br>Der Bauernverband Aargau übernimmt die Kurskosten vollständig.
            Allfällige Materialkosten (z.B. beim Mosten) werden zusätzlich in Rechnung gestellt.
          </p>

         
        </Col>
      </Row>
    </Container>
  )
}

export default Description
