import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
// import DatePicker from 'reactstrap-date-picker'

// import DescriptionMais from '../components/schuleErlebnisseCommon/descriptionMais'


// import moment from 'moment'
import 'moment/locale/de';

import { contentOptions } from './parser'

// const dateFormat = 'dddd Do MMMM YYYY';

import './schuleErlebnisse.scss'


const MaisErlebnisse = ({
  MaisErlebnisse,
}) => {
  // const [date, setDate] = useState(new Date().toISOString())

  // const handleChange = (value) => {
  //   setDate(value)
  // }

  return (

    
    <Container className="schuleErlebnisse-wrapper">
      
      <Row>
          <Col>
            <h1 className="erlebnis-titel">
            {MaisErlebnisse?.titel}
            </h1>
            <br></br>

          </Col>
        </Row>
        
        <Row className="content-wrapper">
          {MaisErlebnisse?.bild?.file?.url && (
            <Col lg="6" xs="12">
              <img src={MaisErlebnisse.bild.file.url} />
            </Col>
          )}
          {/* Kursziele */}
          <Col lg="6" xs="12">
            {/* <h3 className="erlebnis-titel">
              {MaisErlebnisse?.titel}
            </h3> */}
          
            {/* <div className="content-block"> */}
              {/* <h3>So funktioniert die Bauernhoftier-Suche:</h3> */}
              {MaisErlebnisse?.beschreibungMais?.raw
                && documentToReactComponents(JSON.parse(MaisErlebnisse.beschreibungMais.raw), contentOptions)} 
            {/* </div> */}
           
            {/* <div className="content-block-mais">
              
                 {MaisErlebnisse?.beschreibungMais?.raw
                && documentToReactComponents(JSON.parse(MaisErlebnisse.beschreibungMais.raw), contentOptions)}
               {MaisErlebnisse?.kursziele?.raw
                && documentToReactComponents(JSON.parse(MaisErlebnisse.kursziele.raw), contentOptions)}
            </div> */}
            {/* <div className="content-block">
              <h3>Kursleiterin:</h3>
              <div>
                <strong>{schuleErlebnisse?.kursleitung?.name}</strong>
              </div>
              <div>
                {schuleErlebnisse?.kursleitung?.beschreibung?.raw
                  && documentToReactComponents(JSON.parse(schuleErlebnisse.kursleitung.beschreibung.raw), contentOptions)} 
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
  )
}

export default MaisErlebnisse
