import React, { useRef, useState } from "react"
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
} from "reactstrap"
import { toast } from "react-toastify"
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
// import  ReCAPTCHA  from "react-google-recaptcha";

import "./contactForm.scss"

const ContactForm = ({ titles }) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const recaptchaRef = useRef(null);

  // function submitForm(ev) {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //   } else {
  //     ev.preventDefault()
  //     const form = ev.target
  //     const data = new FormData(form)
  //     const xhr = new XMLHttpRequest()
  //     xhr.open(form.method, form.action)
  //     xhr.setRequestHeader("Accept", "application/json")
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState !== XMLHttpRequest.DONE) return
  //       if (xhr.status === 200) {
  //         form.reset()
  //         toast('Ihre Anfrage wurde erfolgreich gesendet',{ type: 'success' })
  //       } else {
  //         toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
  //       }
  //     }
  //     xhr.send(data)
  //   }
  // }



  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)

    // const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();

    // if (!recaptchaValue) {
    //   console.log('Execute recaptcha not yet available');
    //   return;
    // } else {
    // data.append('g-recaptcha-response', recaptchaValue);

    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        toast("Ihre Anfrage wurde erfolgreich gesendet", { type: "success" })
      } else {
        toast(
          "Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal",
          { type: "error" }
        )
      }
      // };
    }
      xhr.send(data)
  }

  return (
    <div>
      {/* <ReCAPTCHA ref={recaptchaRef} sitekey="6LeimdgkAAAAAIJQjdNnCR0kcnKIZjDx65SHn0RJ"> */}
      <Container>
        <Row className="contact-form-block">
          <Col>
            <h2>Terminanfrage für Schulen</h2>
            <Form
              method="POST"
              onSubmit={submitForm}
              action={process.env.GATSBY_FORMSPREE_SCHULE_ENDPOINT}
            >
              <FormGroup>
                <Label for="schule">
                  Schule <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  name="schule"
                  id="schule"
                  placeholder="Schule"
                />
              </FormGroup>
              <FormGroup>
                <Label for="lehrperson">
                  Name der Lehrperson <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  name="lehrperson"
                  id="lehrperson"
                  placeholder="Name der Lehrperson"
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  E-Mail <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail"
                />
              </FormGroup>
              <FormGroup>
                <Label for="gewünschtes">
                  Gewünschtes SchuB Programm <span className="required">*</span>
                </Label>
                <Input type="select" name="gewünschtes" id="gewünschtes">
                  {titles?.map(title => (
                    <option key={title}>{title}</option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="schulstufe">
                  Schulstufe <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  name="schulstufe"
                  id="schulstufe"
                  placeholder="Schulstufe"
                />
              </FormGroup>
              <FormGroup>
                <Label for="anzahlSchüler">
                  Anzahl Schüler <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="number"
                  name="anzahlSchüler"
                  id="anzahlSchüler"
                  placeholder="Anzahl Schüler"
                />
              </FormGroup>
              <FormGroup>
                <Label for="anzahlBegleitpersonen">
                  Anzahl Begleitpersonen <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="number"
                  name="anzahlBegleitpersonen"
                  id="anzahlBegleitpersonen"
                  placeholder="Anzahl Begleitpersonen"
                />
              </FormGroup>
              <FormGroup>
                <Label for="bevorzugteDaten">
                  Bevorzugte Daten <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  name="bevorzugteDaten"
                  id="bevorzugteDaten"
                  placeholder="Bevorzugte Daten"
                />
              </FormGroup>
              <FormGroup>
                <Label for="ankunftsAbreisezeit">
                  Ankunfts- und Abreisezeit <span className="required">*</span>
                </Label>
                <Input
                  required
                  type="text"
                  name="ankunftsAbreisezeit"
                  id="ankunftsAbreisezeit"
                  placeholder="Ankunfts- und Abreisezeit"
                />
              </FormGroup>
              <FormGroup>
                <Label for="nachricht">Nachricht / spezielle Wünsche</Label>
                <Input
                  type="textarea"
                  name="nachricht"
                  id="nachricht"
                  placeholder="Nachricht"
                />
              </FormGroup>
              {/* <div class="g-recaptcha" data-sitekey="6LdpWW8cAAAAACL87AU9sOjgXquN80h9_NnSmLYO"></div> */}
              <FormGroup>
                <Label>
                  <Input style={{marginLeft:'5px'}} required type="checkbox" />{" "}
                  <span style={{ marginLeft: "25px" }}>
                    Ich bin mit den{" "}
                    <a href="https://assets.ctfassets.net/ye599n3dkmr1/2on0yEOP5T4s9Luig9dJW9/6c8651a8b7b8590433d30f71258e4aee/AGB_Fahr_2023.pdf">
                      AGB's
                    </a>{" "}
                    einverstanden
                  </span>
                </Label>
              </FormGroup>
              {/* <div style={{ height: "20px" }}></div> */}
              {<Button>Absenden</Button>}{" "}
              {/* <div style={{height:'20px'}}></div> */}
            </Form>
          </Col>
        </Row>
      </Container>
      {/* </ReCAPTCHA> */}
    </div>
  )
}

export default ContactForm
