import React, { useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { toast } from "react-toastify"
import DatePicker from "reactstrap-date-picker"
// import {
//   useGoogleReCaptcha,
//   GoogleReCaptchaProvider
// } from 'react-google-recaptcha-v3'
// import ReCAPTCHA from "react-google-recaptcha";

import moment from "moment"
import "moment/locale/de"

import { contentOptions } from "../contentfulModels/parser"

import "./erlebnisnachmittag.scss"

const ErlebnisnachmittagPage = ({ erlebnisnachmittag, pdf, daten }) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const recaptchaRef = useRef(null);
  const [date, setDate] = useState(new Date().toISOString())
  const [photos, setPhotos] = useState(false)

  const handleChange = value => {
    setDate(value)
  }

  const dateFormat = "dddd Do MMMM YYYY"

  // function submitForm(ev) {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //   } else {
  //     ev.preventDefault()
  //     const form = ev.target
  //     console.log(ev.target, 'ev.target')
  //     const data = new FormData(form)
  //     console.log(data, 'data')
  //     const xhr = new XMLHttpRequest()
  //     xhr.open(form.method, form.action)
  //     xhr.setRequestHeader("Accept", "application/json")
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState !== XMLHttpRequest.DONE) {
  //         return
  //       }
  //       if (xhr.status === 200) {
  //         form.reset()
  //         toast('Ihre Anfrage wurde erfolgreich gesendet',{ type: 'success' })
  //       } else {
  //         toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
  //       }
  //     }
  //     xhr.send(data)
  //   }
  // }

  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)

    // const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();

    // if (!recaptchaValue) {
    //   console.log('rExecute recaptcha not yet available');
    //   return;
    // }else{
    //   data.append('g-recaptcha-response', recaptchaValue);

    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      }
      if (xhr.status === 200) {
        form.reset()
        toast("Ihre Anfrage wurde erfolgreich gesendet", { type: "success" })
      } else {
        toast(
          "Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal",
          { type: "error" }
        )
      }
    }
    xhr.send(data)
    // }
  }

  return (
    <Container className="wrapper">
      <Row>
        <Col>
          <h1 className="erlebnis-titel">
            {erlebnisnachmittag.name}

            {pdf?.file?.file?.url && (
              <a
                style={{ paddingLeft: "2rem" }}
                className="pdf-url"
                rel="noreferrer"
                target="_blank"
                href={"https://" + pdf?.file?.file?.url}
              >
                PDF
              </a>
            )}
          </h1>
          <div className="content-block">
            {/* <h3>Kursziel:</h3> */}
            {erlebnisnachmittag?.beschreibung?.raw &&
              documentToReactComponents(
                JSON.parse(erlebnisnachmittag.beschreibung.raw),
                contentOptions
              )}
          </div>
          {/* <div className="content-block">
              <h3>Zielgruppe</h3> {erlebnisnachmittag?.zielgruppe} 
            </div>
            <div className="content-block">
              <h3>Treffpunkt</h3> {erlebnisnachmittag?.treffpunkt} 
            </div>
            <div className="content-block">
              <h3>Kurszeiten</h3>
              <div>Start: {erlebnisnachmittag?.anfangZeit} </div>
              <div>Ende: {erlebnisnachmittag?.endZeit} </div>
            </div>
            <div className="content-block">
              <h3>Kursleitung</h3>
              <div>
                <strong>{erlebnisnachmittag?.kursleitung?.[0]?.name}</strong>
              </div>
              <div>
                {erlebnisnachmittag?.kursleitung?.[0]?.beschreibung?.raw
                  && documentToReactComponents(JSON.parse(erlebnisnachmittag.kursleitung[0].beschreibung.raw), contentOptions)} 
              </div>
            </div>
            <div className="content-block">
              <h3>Preis</h3> {erlebnisnachmittag?.kosten}
            </div> */}
          {/* <div className="content-block">
              <h3>Anzahl Plätze:</h3> {erlebnisnachmittag?.anzahlPltze} 
            </div> */}



          {/* <div className="content-block ">
            <h3>Anmeldung Erlebnisse</h3>
            <Form
              method="POST"
              onSubmit={submitForm}
              action={process.env.GATSBY_FORMSPREE_ERLEBNISNACHMITTAG_ENDPOINT}
            >
              <FormGroup>
                <FormGroup tag="fieldset" check>
                  {daten.datum1Anzeigen && (
                    <FormGroup check>
                      {daten?.datum1 && (
                        <Label check>
                          <Input
                            value={`${daten.datum1titel} ${moment(
                              daten.datum1
                            ).format(dateFormat)}`}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(daten.datum1).format(dateFormat)}
                        </Label>
                      )}{" "}
                      {daten.datum1titel && (
                        <label style={{ paddingLeft: "0px" }}>
                          {daten.datum1titel}
                        </label>
                      )}
                    </FormGroup>
                  )}

                  {daten.datum2Anzeigen && (
                    <FormGroup check>
                      {daten?.datum2 && (
                        <Label check>
                          <Input
                            value={`${daten.datum2titel} ${moment(
                              daten.datum2
                            ).format(dateFormat)}`}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(daten.datum2).format(dateFormat)}
                        </Label>
                      )}{" "}
                      {daten.datum2titel && (
                        <label style={{ paddingLeft: "0px" }}>
                          {daten.datum2titel}
                        </label>
                      )}
                    </FormGroup>
                  )}

                  {daten.datum3Anzeigen && (
                    <FormGroup check>
                      {daten?.datum3 && (
                        <Label check>
                          <Input
                            value={`${daten.datum3titel} ${moment(
                              daten.datum3
                            ).format(dateFormat)}`}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(daten.datum3).format(dateFormat)}
                        </Label>
                      )}{" "}
                      {daten.datum3titel && (
                        <label style={{ paddingLeft: "0px" }}>
                          {daten.datum3titel}
                        </label>
                      )}
                    </FormGroup>
                  )}

                  {daten.datum4Anzeigen && (
                    <FormGroup check>
                      {daten?.datum4titel && (
                        <Label check>
                          <Input
                            value={`${daten.datum4Tittel} ${moment(
                              daten.datum4titel
                            ).format(dateFormat)}`}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(daten.datum4titel).format(dateFormat)}
                        </Label>
                      )}{" "}
                      {daten.datum4Tittel && (
                        <label style={{ paddingLeft: "0px" }}>
                          {daten.datum4Tittel}
                        </label>
                      )}
                    </FormGroup>
                  )}

                 
                </FormGroup>

                <FormGroup className="mt-4">
                  <Label for="kinder">
                    Vorname<span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="kinder"
                    id="kinder"
                    placeholder="Vorname Kind / Kinder / Erwachsene"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="familienname">
                    Nachname <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Familienname"
                    id="familienname"
                    placeholder="Familienname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="kontaktperson">
                    Kontaktperson <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Kontaktperson"
                    id="kontaktperson"
                    placeholder="Kontaktperson"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Begleitperson1">Begleitperson 1 </Label>
                  <Input
                    type="text"
                    name="Begleitperson1"
                    id="Begleitperson1"
                    placeholder="Vorname, Nachname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Begleitperson2">Begleitperson 2</Label>
                  <Input
                    type="text"
                    name="Begleitperson2"
                    id="Begleitperson2"
                    placeholder="Vorname, Nachname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="adresse">
                    Adresse <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Adresse"
                    id="adresse"
                    placeholder="Adresse"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="wohnort">
                    PLZ / Wohnort <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Postleitzahl"
                    id="wohnort"
                    placeholder="PLZ / Wohnort"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Geburtsdatum (jüngstes) Kind</Label>
                  <DatePicker
                    id="example-datepicker"
                    value={date}
                    name="Geburts-Datum"
                    onChange={(v, f) => handleChange(v, f)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="wohnort">Geburtsdaten (weitere Kinder)</Label>
                  <Input
                    type="text"
                    name="Geburtsdaten"
                    id="Geburtsdaten"
                    placeholder="Geburtsdaten"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="telefonnummer">
                    Telefonnummer <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Telefonnummer"
                    id="telefonnummer"
                    placeholder="Telefonnummer"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="email">
                    E-Mail <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="fotos">Fotos </Label>
                  <FormGroup check>
                    <Label check>
                      <Input
                        value={photos}
                        checked={photos}
                        name="fotos-erlaubt"
                        onClick={() => setPhotos(!photos)}
                        type="radio"
                      />{" "}
                      Wir sind einverstanden, dass Fotos unseres Kindes auf
                      dieser Webseite veröffentlicht werden.
                    </Label>
                  </FormGroup>
                </FormGroup>

                <FormGroup>
                  <Label for="nachricht">Nachricht </Label>
                  <Input
                    type="textarea"
                    name="Nachricht"
                    id="nachricht"
                    placeholder="Nachricht"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <Input
                      style={{ marginLeft: "5px" }}
                      required
                      type="checkbox"
                    />{" "}
                    <span style={{ marginLeft: "25px" }}>
                      Ich bin mit den{" "}
                      <a href="https://assets.ctfassets.net/ye599n3dkmr1/2on0yEOP5T4s9Luig9dJW9/6c8651a8b7b8590433d30f71258e4aee/AGB_Fahr_2023.pdf">
                        AGB's
                      </a>{" "}
                      einverstanden
                    </span>
                  </Label>
                </FormGroup>
              </FormGroup>


              <Button type="submit">Absenden</Button>
              <div style={{ height: "20px" }}></div>
            </Form>
          </div> */}
        </Col>
      </Row>
    </Container>
  )
}

export default ErlebnisnachmittagPage
