import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
// import DatePicker from 'reactstrap-date-picker'

// import moment from 'moment'
import 'moment/locale/de';

import { contentOptions } from '../contentfulModels/parser'

// const dateFormat = 'dddd Do MMMM YYYY';

import './schuleErlebnisse.scss'

const schuleErlebnisse = ({
  schuleErlebnisse,
}) => {
  // const [date, setDate] = useState(new Date().toISOString())

  // const handleChange = (value) => {
  //   setDate(value)
  // }

  return (
    <Container className="schuleErlebnisse-wrapper">
        <Row>
          <Col>
            <h1 className="erlebnis-titel">
              {schuleErlebnisse?.titel}
            </h1>

          </Col>
        </Row>
        <Row className="content-wrapper">
          {schuleErlebnisse?.bild?.file?.url && (
            <Col lg="6" xs="12">
              <img src={schuleErlebnisse.bild.file.url} />
            </Col>
          )}
          {/* Kursziele */}
          <Col lg="6" xs="12">
            <div className="content-block">
              <h3>Dauer</h3> {schuleErlebnisse?.dauerText } 
            </div>
            <div className="content-block">
              <h3>Beschreibung</h3>
              {schuleErlebnisse?.beschreibungSchule?.raw
                && documentToReactComponents(JSON.parse(schuleErlebnisse.beschreibungSchule.raw), contentOptions)} 
            </div>
            <div className="content-block">
              <h3>Kursziele</h3>
              {schuleErlebnisse?.kursziele?.raw
                && documentToReactComponents(JSON.parse(schuleErlebnisse.kursziele.raw), contentOptions)} 
            </div>
            {/* <div className="content-block">
              <h3>Kursleiterin:</h3>
              <div>
                <strong>{schuleErlebnisse?.kursleitung?.name}</strong>
              </div>
              <div>
                {schuleErlebnisse?.kursleitung?.beschreibung?.raw
                  && documentToReactComponents(JSON.parse(schuleErlebnisse.kursleitung.beschreibung.raw), contentOptions)} 
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
  )
}

export default schuleErlebnisse
