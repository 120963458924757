import React, { useState, useRef, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import { toast } from "react-toastify"
// import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import ReCAPTCHA from "react-google-recaptcha"

import moment from "moment"
import "moment/locale/de"

// import { contentOptions } from './parser'

import "./erlebnisnachmittag.scss"
// import ReCaptcha from "@pittica/gatsby-plugin-recaptcha"

const JanuarForm = ({ erlebnisnachmittag }) => {
  // const { executeRecaptcha } = useGoogleReCaptcha();
  // const recaptchaRef = useRef(null);
  const [date, setDate] = useState(new Date().toISOString())
  const [photos, setPhotos] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const handleChange = value => {
    setDate(value)
  }

  const dateFormat = "dddd Do MMMM YYYY"

  // function submitForm(ev, token) {
  //   if (!executeRecaptcha) {
  //     console.log('Execute recaptcha not yet available');
  //   } else {
  //     ev.preventDefault()
  //     const form = ev.target
  //     const data = new FormData(form)

  //     const xhr = new XMLHttpRequest()
  //     xhr.open(form.method, form.action)
  //     xhr.setRequestHeader("Accept", "application/json")
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState !== XMLHttpRequest.DONE) return
  //       if (xhr.status === 200) {
  //         form.reset()
  //         toast('Ihre Anfrage wurde erfolgreich gesendet',{ type: 'success' })
  //       } else {
  //         toast('Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal', { type: 'error'})
  //       }
  //     }
  //     xhr.send(data)
  //   }
  // }

  function submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    // console.log(ev.target, 'ev.target');
    const data = new FormData(form)
    // console.log(data, 'data');

    // const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue();
    // console.log(recaptchaValue, "val")

    // if (!recaptchaValue) {
    //   console.log('Execute recaptcha not yet available');
    //   return;
    // } else{
    //   data.append('g-recaptcha-response', recaptchaValue);

    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return
      }
      if (xhr.status === 200) {
        form.reset()
        toast("Ihre Anfrage wurde erfolgreich gesendet", { type: "success" })
      } else {
        toast(
          "Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal",
          { type: "error" }
        )
      }
      // };
    }
      xhr.send(data)
  }

  return (
    // <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>

    // TODO Recaptchav2  1 / 2 Forms

    // <ReCAPTCHA ref={recaptchaRef} sitekey="6LeimdgkAAAAAIJQjdNnCR0kcnKIZjDx65SHn0RJ">

    <Container className="wrapper">
      <Row>
        <Col>
          {/* <h1 className="erlebnis-titel">
              {erlebnisnachmittag.name}
            </h1>
            <div className="content-block">
               <h3>Kursziel:</h3>
              {erlebnisnachmittag?.beschreibung?.raw
                && documentToReactComponents(JSON.parse(erlebnisnachmittag.beschreibung.raw), contentOptions)} 
            </div>
            <div className="content-block">
              <h3>Zielgruppe</h3> {erlebnisnachmittag?.zielgruppe} 
            </div>
            <div className="content-block">
              <h3>Treffpunkt</h3> {erlebnisnachmittag?.treffpunkt} 
            </div>
            <div className="content-block">
              <h3>Kurszeiten</h3>
              <div>Start: {erlebnisnachmittag?.anfangZeit} </div>
              <div>Ende: {erlebnisnachmittag?.endZeit} </div>
            </div>
            <div className="content-block">
              <h3>Kursleitung</h3>
              <div>
                <strong>{erlebnisnachmittag?.kursleitung?.[0]?.name}</strong>
              </div>
              <div>
                {erlebnisnachmittag?.kursleitung?.[0]?.beschreibung?.raw
                  && documentToReactComponents(JSON.parse(erlebnisnachmittag.kursleitung[0].beschreibung.raw), contentOptions)} 
              </div>
            </div>
            <div className="content-block">
              <h3>Preis</h3> {erlebnisnachmittag?.kosten}
            </div> */}
          {/* <div className="content-block">
              <h3>Anzahl Plätze:</h3> {erlebnisnachmittag?.anzahlPltze} 
            </div> */}
          <div className="content-block ">
            <h3>Anmeldung</h3>
            <Form
              method="POST"
              onSubmit={submitForm}
              action={process.env.GATSBY_FORMSPREE_JANUAR_ENDPOINT}
            >
              <FormGroup>
                <FormGroup tag="fieldset" check>
                  {/* {erlebnisnachmittag?.datum1anzeigen && ( */}

                    <FormGroup check>
                      {erlebnisnachmittag?.datum1 && (
                        <Label check>
                          <Input
                            value={moment(erlebnisnachmittag.datum1).format(
                              dateFormat
                            )}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(erlebnisnachmittag.datum1).format(dateFormat)}
                        </Label>
                      )}
                    </FormGroup>
                  {/* )} */}

                  {erlebnisnachmittag?.datum2anzeigen && (
                    <FormGroup check>
                      {erlebnisnachmittag?.datum2 && (
                        <Label check>
                          <Input
                            value={moment(erlebnisnachmittag.datum2).format(
                              dateFormat
                            )}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(erlebnisnachmittag.datum2).format(dateFormat)}
                        </Label>
                      )}
                    </FormGroup>
                  )}

                  {erlebnisnachmittag?.datum3anzeigen && (
                    <FormGroup check>
                      {erlebnisnachmittag?.datum3 && (
                        <Label check>
                          <Input
                            value={moment(erlebnisnachmittag.datum3).format(
                              dateFormat
                            )}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(erlebnisnachmittag.datum3).format(dateFormat)}
                        </Label>
                      )}
                    </FormGroup>
                  )}

                  {erlebnisnachmittag?.datum4anzeigen && (
                    <FormGroup check>
                      {erlebnisnachmittag?.datum4 && (
                        <Label check>
                          <Input
                            value={moment(erlebnisnachmittag.datum4).format(
                              dateFormat
                            )}
                            type="checkbox"
                            name="Datum"
                          />{" "}
                          {moment(erlebnisnachmittag.datum4).format(dateFormat)}
                        </Label>
                      )}
                    </FormGroup>
                  )}
                </FormGroup>

                <FormGroup className="mt-4">
                  <Label for="Name">
                    Vorname <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="name"
                    id="Name"
                    placeholder="Name des Kindes / der Kinder / Erwachsene"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="familienname">
                    Familienname <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Familienname"
                    id="familienname"
                    placeholder="Familienname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Begleit1">Begleitperson 1</Label>
                  <Input
                    type="text"
                    name="begleit1"
                    id="begleit1"
                    placeholder="Vorname, Nachname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="Begleit2">Begleitperson 2</Label>
                  <Input
                    type="text"
                    name="begleit2"
                    id="Begleit2"
                    placeholder="Vorname, Nachname"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="adresse">
                    Adresse <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Adresse"
                    id="adresse"
                    placeholder="Adresse"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="wohnort">
                    PLZ / Wohnort <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Postleitzahl"
                    id="wohnort"
                    placeholder="PLZ / Wohnort"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="telefonnummer">
                    Telefonnummer <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="text"
                    name="Telefonnummer"
                    id="telefonnummer"
                    placeholder="Telefonnummer"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="email">
                    E-Mail <span className="required">*</span>
                  </Label>
                  <Input
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-Mail"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="nachricht">Nachricht </Label>
                  <Input
                    type="textarea"
                    name="Nachricht"
                    id="nachricht"
                    placeholder="Nachricht"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <Input style={{marginLeft:'5px'}} required type="checkbox" />{" "}
                    <span style={{ marginLeft: "25px" }}>
                      Ich bin mit den{" "}
                      <a href="https://assets.ctfassets.net/ye599n3dkmr1/2on0yEOP5T4s9Luig9dJW9/6c8651a8b7b8590433d30f71258e4aee/AGB_Fahr_2023.pdf">
                        AGB's
                      </a>{" "}
                      einverstanden
                    </span>
                  </Label>
                </FormGroup>
              </FormGroup>
              {/* <div style={{ height: "20px" }}></div> */}

              <Button>Absenden</Button>
              <div style={{ height: "20px" }}></div>
              {/* <ReCaptcha
        action="homepage"
        siteKey="6LcBdm8cAAAAAK0sNk2AZhv0kq4a-ENvz82fEJmY"
        onVerify={(token) => submit(token)}
        submitted={submitted}
      /> */}
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
    // </ReCAPTCHA>
  )
}

export default JanuarForm
